import * as React from "react"
import QuestionLayout from "../../components/question-layout/question-layout"

const QuestionPage2 = () => {
  return (
    <QuestionLayout
      backgroundColor="blue"
      contentHTML="
        Early news report suggest that a Bitcoin wallet from the Satoshi era containing 100,000 BTC has been lost forever.  Do you wait for the market to react or do you take action immediately?
      "
      buyButtonText="Buy"
      buyButtonLink="/answer/2/correct"
      sellButtonText="Sell"
      sellButtonLink="/answer/2/wrong"
      balanceText="Your Balance:"
      currentStep="2"
      images={{
        map: {
          show: true,
          factor: 0.04
        },
        washingtonTop: {
          show: false,
          factor: 0.08
        },
        dotsLeft: {
          show: true,
          factor: 0.12
        },
        chartDown: {
          show: false,
          factor: 0.16
        },
        dollarSmall: {
          show: false,
          factor: 0.2
        },
        bitcoin: {
          show: false,
          factor: 0.24
        },
        plusesHorizontal: {
          show: false,
          factor: 0.28
        },
        plusesHorizontalGreen: {
          show: true,
          factor: 0.28
        },
        washingtonBottom: {
          show: false,
          factor: 0.32
        },
        dollarBig: {
          show: false,
          factor: 0.36
        },
        plusesVertical: {
          show: false,
          factor: 0.4
        },
        plusesVerticalGreen: {
          show: true,
          factor: 0.4
        },
        dotsRight: {
          show: true,
          factor: 0.4
        },
        chartUp: {
          show: false,
          factor: 0.48
        },
        satoshiTop: {
          show: true,
          factor: 0.08
        },
        satoshiBottom: {
          show: true,
          factor: 0.32
        },
        bankTop: {
          show: false,
          factor: 0.08
        },
        bankBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowGreenRedTop: {
          show: false,
          factor: 0.08
        },
        chartArrowGreenRedBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowGreenTop: {
          show: false,
          factor: 0.08
        },
        chartArrowGreenBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowYellowTop: {
          show: false,
          factor: 0.08
        },
        chartArrowYellowBottom: {
          show: false,
          factor: 0.32
        },
        questionMarkTop: {
          show: true,
          factor: 0.12
        },
        questionMarkBottom: {
          show: true,
          factor: 0.32
        },
        chartGreenUp: {
          show: true,
          factor: 0.48
        },
        chartGreenDown: {
          show: true,
          factor: 0.16
        },
        bitcoinBottom: {
          show: true,
          factor: 0.36
        }
      }}
    ></QuestionLayout>
  )
}

export default QuestionPage2